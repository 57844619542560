$(window).load(function() {
  $('.flexslider').flexslider({
    animation: "slide",
    animationLoop: true
  });
});

// ---------------------------

// $(document).ready(function() {
//   $('.flexslider').flexslider({
//     animation: "slide",
//     animationLoop: true
//   });
// });


// $(window).ready(function() {
//   $('.flexslider').flexslider({
//     animation: "slide",
//     animationLoop: true
//   });
// });

// ---------------------------------

$('.work__item').hover(
    function(){ $(this).addClass('pulse'); },
    function(){ $(this).removeClass('pulse'); }
);

// $('.fa-angle-right').hover(
//     function(){ $(this).addClass('rubberBand'); },
//     function(){ $(this).removeClass('rubberBand'); }
// );

// -------------------------------------

$(document).ready(function(){
  $(".hamburger").hover(function(){
    $(this).toggleClass("is-active");
  });
});

$(".menu_ul").mouseenter(function(){
    $('.hamburger').addClass('is-active')
    }).mouseleave(function(){
    $('.hamburger').removeClass('is-active')
    });


// $('#example2').click(function() {
// 	var c = $('<div class="box-modal" />');
// 	c.html($('.b-text').html());
// 	c.prepend('<div class="box-modal_close arcticmodal-close">закрыть</div>');
// 	$.arcticmodal({
// 		content: c
// 	});
// });


// $(function(){
//     $('#exampleModal1').arcticmodal();
// });

//пример
// <твой элемент>.onclick = function() {
// MyFunc()

// document.getElementById('.sale__click').onclick = function() {
// ('#exampleModal1').arcticmodal();
// };




$('.sale__click').click(function(){
    $('#Modal1').arcticmodal();
});


// $('.sale__click').click(function(){
//     $('#Modal1').arcticmodal({
//       afterClose: function(data, el) { 
//         // document.getElementById('body').className = 'bodyscrol',
//         document.body.style.overflowX = "hidden";
// }
//       });
// });



// $('.sale__click').arcticmodal({
//     beforeOpen: function(data, el) {
//         $('#Modal1').css({'overflow':'hidden'});
//     },
//     beforeClose: function(data, el) {
//         $('#Modal1').css({'overflow-x':'hidden'});
//     }
// })




 // $('#Modal1').arcticmodal({
 //    afterClose: function(){
 //    $('body').css('overflow-x','hidden');
 //    }
 //    });

// $('.history__music_img').click(
//     function(){ $(this).addClass('fadeInLeftBig'); },
//     function(){ $(this).removeClass('fadeInLeftBig'); }
// );


// window.onload = function () { 
//     document.body.style.overflowX = "hidden";
// }



// Hide Header on on scroll down
var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = $('header').outerHeight();

$(window).scroll(function(event){
    didScroll = true;
});

setInterval(function() {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);

function hasScrolled() {
    var st = $(this).scrollTop();
    
    // Make sure they scroll more than delta
    if(Math.abs(lastScrollTop - st) <= delta)
        return;
    
    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight){
        // Scroll Down
        $('header').removeClass('nav-down').addClass('nav-up');
    } else {
        // Scroll Up
        if(st + $(window).height() < $(document).height()) {
            $('header').removeClass('nav-up').addClass('nav-down');
        }
    }
    
    lastScrollTop = st;
}

// ----------------------------

var pageYLabel = 0; //переменная будет хранить координаты прокрутки

 $(window)
 .load(function() {
  var pageY = $(window).scrollTop();
 if (pageY > 200) {
  $("#up").show();
 } //при загрузке страницы если прокрутка больше 200px - показывать кнопку вверх
 })
 .scroll(function(e){
  var pageY = $(window).scrollTop();
  var innerHeight = $(window).innerHeight();
  var docHeight = $(document).height();
 if (pageY > innerHeight) {
         $("#up").show();
 }else{$("#up").hide();}

 if (pageY < innerHeight && pageYLabel >= innerHeight) {
         $("#down").show();
 }else{$("#down").hide();}
 }); //действия при прокрутке страницы

 $('#up').click(
 function() {
  var pageY = $(window).scrollTop();
  pageYLabel = pageY; //запоминаем где был совершен клик
  $("html,body").animate({scrollTop:0},"slow");
 });
 $('#down').click(
 function(){
  $("html,body").animate({scrollTop:pageYLabel},"slow"); //при возврате прокручиваем страницу к месту клика по кнопке
 });

// --------

// $(window).scroll(function(){
//     $('.updown').toggleClass('bounce', $(this).scrollTop() > 0);
// });


$(window).scroll(function(){
        if ( $(this).scrollTop() > 0 ) {
            $('.updown').toggleClass('bounce');
        } else {
            $('.updown').removeClass('bounce');
        }
    })



// ---------------------------

var scene = document.getElementById('scene');
var parallaxInstance = new Parallax(scene);

var scene = document.getElementById('scene2');
var parallaxInstance = new Parallax(scene);

var scene = document.getElementById('scene3');
var parallaxInstance = new Parallax(scene);
